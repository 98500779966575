<template>
  <div>
    <div v-if="!error" class="detail-wrapper">
      <div class="side-wrapper">
        <ul class="list-group list-group-flush">
          <a
            v-for="(col, i) in side_cols"
            :key="i"
            href="#"
            class="list-group-item list-group-item-action flex-column align-items-start side-item"
            v-bind:class="{ selected: i == colIdxSelected }"
            @click="sideColClicked(i)"
          >
            {{ col }}
          </a>
        </ul>
      </div>
      <div class="list-wrapper" v-on:scroll.passive="handleScroll">
        <div
          v-if="json_data"
          class="text-left m-2 font-weight-bold fixed-title"
        >
          <h4>Protein: {{ json_data.name }}</h4>
        </div>
        <BaseEntry
          v-for="(e, i) in entries"
          :selectedColIdx="colIdxSelected"
          :currIdx="i"
          :key="i"
          :content="e"
          :ref="`entry` + i"
        ></BaseEntry>
      </div>
    </div>
    <div v-else>
      <PageNotFound />
    </div>
  </div>
</template>
<script>
//http://localhost:8082/protein?key=1005236661
import BaseEntry from "@/components/detail/BaseEntry";
import PostsService from "@/services/PostsService";
import PageNotFound from "@/components/common/PageNotFound";
export default {
  name: "ProteinDetail",
  components: {
    BaseEntry,
    PageNotFound,
  },
  data() {
    return {
      loading: false,
      error: false,
      base_url: process.env.VUE_APP_S3_PATH + "protein/",
      json_data: null,
      side_cols: [
        "Sequence",
        "Summary",
        "Properties",
        "Domains",
        "External Links",
      ],
      colIdxSelected: 0,
      entries: [],
      entryPosys: [],
      helpTexts: {
        domains: `<b>Domain source:</b> Domain information was obtained using the interproscan.pl program and databases from INTERPRO and all Arabidopsis protein sequences. Each source database uses a different method or algorithm to generate the domain. INTERPRO domains can include one or more domains from the member databases.

<ul>
  <li>
    <b>PFAM:</b> Pfam is a database of multiple alignments of protein domains or conserved protein regions. Pfam domains are generated using multiple sequence alignments to generate a seed alignment. Hidden Markov Models are then used to group related sequences. Pfam families include PfamA which are curated and PfamB which are computationally derived and not inspected by experts.
  </li>
  <li>
    <b>PRINTS:</b> The PRINTS database is a collection of protein fingerprints; conserved motifs characteristic of a protein family. Usually the motifs do not overlap, but are separated along a sequence, though they may be contiguous in 3D-space. Fingerprints can encode protein folds and functionalities more flexibly and powerfully than can single motifs, full diagnostic potency deriving from the mutual context provided by motif neighbors.
  </li>
  <li>
    <b>PRODOM:</b> Prodom families are built using a recursive PSI-BLAST homology search. Only those ProDom families included in INTERPRO are included in the ProDom assignments for Arabidopsis proteins.
  </li>
  <li>
    <b>PROSITE:</b> Prosite includes protein families and domains; biologically significant sites, patterns and profiles that help to reliably identify to which known protein family (if any) a new sequence belongs. Annotation of proteins with Prosite domains can be done automatically by searching with regular expressions for matches to Prosite patterns or by matching a sequence to a profile for finding more distantly related proteins.
  </li>
  <li>
    <b>SMART:</b> The SMART database includes more than 500 domain families found in signaling, extracellular and chromatin-associated proteins are detectable. These domains are extensively annotated with respect to phyletic distributions, functional class, tertiary structures and functionally important residues.SMART alignments are optimized manually and following construction of corresponding hidden Markov models (HMMs). Association of proteins to SMART families is done by searching with the protein sequence against the SMART library of HMMs.
  </li>
  <li>
    <b>SUPERFAM:</b> A designation used by SCOP for proteins that have low sequence identities, but whose structural and functional features suggest that a common evolutionary origin is probable are placed together in superfamilies. SCOP uses structural similarity to group proteins into families, superfamilies and folds.
  </li>
  <li>
    <b>TIGRFAM:</b> TIGRFAM protein families are generated from curated multiple sequence alignments and Hidden Markov Models. Curated families are also associated to functional roles to facilitate automated functional identification of proteins by sequence homology.
  </li>
</ul>

`,
        accession: `<b>TAIR Accession</b> The unique identifier for a record in TAIR. The accession can be used to create external links to TAIR detail pages from other databases or websites. For information on how to create links using the accessions see our page on Hyperlinking to TAIR under the About TAIR section of the website.`,
        dateModified: `<b>date_last_modified</b> The date this record was last updated or changed.`,
        externalIds: `<b>External IDs</b> These are links to other databases or websites containing information relevant to the object being displayed. For example, it may be a link to an external database's detail page for a gene or locus, or a link to a marker generating software for polymorphisms.`,
        calculatedMW: `<b>calculated molecular weight</b> Predicted molecular weight (in daltons) of a protein.The predictions were calculated using the Bioperl function get_mol_wt (found in the SeqStats object of Bioperl 0.7).`,
        calculatedPI: `<b>calculated isoelectric point</b> Predicted isoelectric point calculated using the iep program in the EMBOSS package. EMBOSS version: 2.0.1.`,
        length: `<b>length</b> Total length of the amino acid sequence in terms of numbers of amino acids.`,
        structuralTypes: `
<b>Structural Class Types:</b>
<ul>
  <li>
    Classification system developed by SCOP (Structural Classification of Proteins) based on protein topology that uses Hidden Markov Models (HMMs) to assign proteins to classes. Classes are defined by the type and arrangements of folds. Each class is further broken down into superfamilies and families.
  </li>
  <li>
    <b>All alpha proteins:</b> Includes all proteins with one or more alpha helices arranged in a variety of ways. These include bundles, alpha helices oriented roughly along the same axis; folded leaf, a layer of alpha helices wrapped around a single hydrophobic core but not with the simple geometry of a bundle; array(of hairpins), an assembly of alpha-helices that cannot be described as a bundle or a folded leaf.
  </li>
  <li>
    <b>All beta proteins:</b> Includes all proteins with beta sheet fold topology. Beta sheets can be antiparallel - the direction of any two adjacent strands are antiparallel; parallel - all strands are parallel to each other and mixed - in which at least one strand is parallel to one neighbor and antiparallel to another.
  </li>
  <li>
    <b>Alpha and beta proteins (a/b):</b> Refers to proteins in which alpha helices and beta are interspersed throughout the protein.
  </li>
  <li>
    <b>Alpha and beta proteins (a+b):</b> Proteins in which alpha helices and beta sheets are spatially segregated within the protein.
  </li>
  <li>
    <b>Multi-domain proteins (alpha and beta):</b> Structural class of proteins with novel folds, and proteins without any known homologs.
  </li>
  <li>
    <b>Membrane and cell surface proteins and peptides:</b> Proteins that are predicted to be localized to membranes or anchored to the cell surface based on the presence of folds corresponding to transmembrane domains.
  </li>
  <li>
    <b>Small proteins:</b> Usually dominated by metal ligand, heme, and/or disulfide bridges.
  </li>
</ul>
`,
        comments: `<b>Comments</b> Comments displayed include comments contributed by TAIR curators or other members of the community. Comments are associated to particular data on the data detail pages. Registered users can submit comments on any detail page. The comments are then displayed along with the name of the contributor. Comments are reviewed by curators who may perform updates or corrections to data based upon the contributors comments.`,
      },
    };
  },
  async mounted() {
    if (this.$route.query.key) {
      let jsonId = this.$route.query.key;
      try {
        let response = await PostsService.getProteinDetail({ key: jsonId });
        this.json_data = response.data;

        this.processEntries();
        document.title = this.json_data.name;
        setTimeout(() => {
          this.entries.forEach((e, i) => {
            let entryPagePosY = this.$refs["entry" + i][0].$el.offsetTop;

            this.entryPosys.push(entryPagePosY);
          });
        }, 1000);
      } catch (error) {
        console.error("Error fetching protein details: ", error);
        this.loading = false;
        this.error = true;
      }
    } else {
      this.loading = false;
      this.error = true;
    }
  },
  methods: {
    scrollToElement(colIdx) {
      const entryRef = this.$refs["entry" + colIdx];
      const el = entryRef[0].$el;
      if (el) {
        var top = el.offsetTop - 210;
        el.parentElement.scrollTo(0, top);
      }
    },
    handleScroll(e) {
      let scrollTop = e.target.scrollTop + 215;
      let currTopEntry = 0;
      this.entryPosys.forEach((e, i) => {
        if (scrollTop > e) {
          currTopEntry = i;
        }
      });
      this.colIdxSelected = currTopEntry;
    },
    sideColClicked(colIdx) {
      this.scrollToElement(colIdx);
      this.colIdxSelected = colIdx;
    },
    async getProteinDetail(id) {
      let url = this.base_url + id + ".json";
      return axios({
        method: "get",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
        url: url,
      });
    },
    processEntries() {
      this.side_cols.forEach((title) => {
        let entry = {
          title: title,
          name: title.replace(/\s/g, ""),
        };
        switch (title) {
          case "Summary":
            entry.type = "bands";
            entry.bands = this.fillSummary();
            break;
          case "Properties":
            entry.type = "bands";
            entry.bands = this.fillProperties();
            break;
          case "Domains":
            entry.type = "bands";
            entry.bands = this.fillDomains();
            entry.helpTxt = this.helpTexts.domains;
            break;
          case "Sequence":
            entry.type = "bands";
            entry.bands = this.fillSequences();
            break;
          case "External Links":
            entry.type = "bands";
            entry.bands = this.fillExternalLinks();
            break;
          default:
            entry.type = "bands";
            entry.bands = [];
            break;
        }
        this.entries.push(entry);
      });
    },
    fillSummary() {
      let bands = [];
      bands.push({
        key: "Name",
        text: this.json_data.name,
      });
      bands.push(this.getExternalIds());
      bands.push(this.getAssociatedGenes());
      bands.push({
        key: "Date last modified",
        text: this.getDateModified(),
        helpTxt: this.helpTexts.dateModified,
      });
      bands.push({
        key: "TAIR Accession",
        text: "AASequence:" + this.json_data.tairAccession,
        helpTxt: this.helpTexts.accession,
      });
      bands.push(this.getComments());
      return bands;
    },
    fillProperties() {
      let bands = [];
      bands.push({
        key: "Calculated MW",
        text: this.json_data.proteinProperties
          ? this.json_data.proteinProperties.calculatedMW
          : "No data available",
        helpTxt: this.helpTexts.calculatedMW,
      });
      bands.push({
        key: "Calculated PI",
        text: this.json_data.proteinProperties
          ? this.json_data.proteinProperties.calculatedPI
          : "No data available",
        helpTxt: this.helpTexts.calculatedPI,
      });
      bands.push({
        key: "Length (aa)",
        text: this.json_data.proteinProperties
          ? this.json_data.proteinProperties.length
          : "No data available",
        helpTxt: this.helpTexts.length,
      });
      return bands;
    },
    fillDomains() {
      let bands = [];
      bands.push(this.getDomains());
      return bands;
    },
    fillSequences() {
      let bands = [];
      bands.push(this.getSequence());
      return bands;
    },
    fillExternalLinks() {
      let bands = [];
      bands.push(this.getExternalLinks());
      return bands;
    },
    getExternalIds() {
      let entry = {
        key: "External Ids",
        type: "table",
        items: [],
        fields: [],
        helpTxt: this.helpTexts.externalIds,
      };
      entry.fields.push({
        key: "uniprotkb",
        label: "UniProtKB",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "genpept",
        label: "GenPept",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "proteins",
        label: "Similar Proteins in Genbank",
        cellType: "name_link",
      });
      if (!this.json_data.externalIdsList) {
        entry.text = "No external ids available";
        entry.type = null;
        return entry;
      }
      this.json_data.externalIdsList.forEach((s) => {
        entry.items.push({
          uniprotkb: {
            name: s.uniprotId,
            link: "https://www.uniprot.org/uniprot/" + s.uniprotId,
          },
          genpept: {
            name: s.genbankGi,
            link:
              "https://www.ncbi.nlm.nih.gov/protein?cmd=Retrieve&dopt=GenPept&list_uids=" +
              s.genbankGi,
          },
          proteins: {
            name: "NCBI BLink",
            link:
              "http://www.ncbi.nlm.nih.gov/sutils/blink.cgi?pid=" + s.genbankGi,
          },
        });
      });
      return entry;
    },
    getAssociatedGenes() {
      let entry = {
        key: "Associated Genes",
        type: "table",
        items: [],
        fields: [],
      };
      entry.fields.push({
        key: "geneModel",
        label: "Gene Model",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "locus",
        label: "Locus",
        cellType: "name_link",
      });
      if (
        !this.json_data.associatedGenes ||
        this.json_data.associatedGenes.length == 0
      ) {
        entry.type = null;
        entry.text = "No associated genes available";
        return entry;
      }
      if (this.json_data.associatedGenes.length == 1) {
        let gene1 = this.json_data.associatedGenes[0];
        if (gene1.geneId == null || gene1.geneId == 0) {
          entry.type = null;
          entry.text = "No associated genes available";
          return entry;
        }
      }

      let gene1 = this.json_data.associatedGenes[0];
      if (gene1.geneId == null || gene1.geneId == 0) {
        return entry;
      }
      let item = {
        geneModel: {
          name: gene1.name,
          link: "/gene?key=" + gene1.geneId,
        },
        // seqviewer: {
        //   name: "Sequence Viewer",
        //   link:
        //     "https://seqviewer.arabidopsis.org/?action=accession&type=gene&id=" +
        //     gene1.geneId +
        //     "&chr=1",
        // },
      };
      let locus = {};
      locus.name = gene1.associatedLocus
        ? gene1.associatedLocus.name
        : "Unknown";
      if (gene1.associatedLocus) {
        locus.link = "/locus?key=" + gene1.associatedLocus.locusId;
      }
      item.locus = locus;
      entry.items.push(item);

      return entry;
    },
    getDomains() {
      let entry = {
        key: "Domains",
        type: "table",
        items: [],
        fields: [],
      };
      entry.fields.push({
        key: "database",
        label: "Database",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "struct",
        label: "Structural Class Type",
        cellType: "name_link",
        helpTxt: this.helpTexts.structuralTypes,
      });
      entry.fields.push({
        key: "accession",
        label: "Accession",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "interpro",
        label: "INTERPRO",
        cellType: "name_link",
      });
      entry.fields.push({
        key: "position",
        label: "Position",
        cellType: "name_link",
      });
      if (!this.json_data.domains) {
        entry.text = "No domains available";
        entry.type = null;
        return entry;
      }
      this.json_data.domains.forEach((d) => {
        let item = {};
        item.position = {
          name: d.startPosition + "-" + d.endPosition,
        };
        if (d.domainDetails) {
          let interproId = d.domainDetails.interproAccession;
          let name = d.domainDetails.interproName;
          if (name != null || interproId != null) {
            item.interpro = {
              name: name + ":" + interproId,
              link: "http://www.ebi.ac.uk/interpro/entry/" + interproId,
            };
          }

          item.database = {
            name: d.domainDetails.proteindomainType.toUpperCase(),
          };
          item.accession = {
            name: d.domainDetails.proteinDomainAccession,
          };
          item.struct = {
            name: d.domainDetails.description,
          };
        }
        entry.items.push(item);
      });
      entry.count = entry.items.length;
      return entry;
    },
    getSequence() {
      let entry = {
        key: "Sequence",
        type: "sequence",
        sequences: [],
      };
      if (this.json_data.sequence) {
        const lines = [];
        const sequenceLength = this.json_data.sequence.length;
        let lineNumber = 1;

        for (let i = 0; i < sequenceLength; i += 50) {
          const lineChunks = [];
          for (let j = i; j < i + 50 && j < sequenceLength; j += 10) {
            lineChunks.push(this.json_data.sequence.slice(j, j + 10));
          }

          lines.push(
            `${lineNumber.toString().padStart(3, "0")} ${lineChunks.join(" ")}`
          );
          lineNumber += 50;
        }
        entry.chunks = lines;
        entry.openLink = () => {
          this.openPostLink(
            process.env.VUE_APP_OLD_TAIR_URL + "/Blast/index.jsp",
            {
              sequence_type: "protein",
              sequence: this.json_data.sequence,
            }
          );
        };
      } else {
        entry.chunks = ["No sequence available"];
      }
      return entry;
    },
    getExternalLinks() {
      let entry = {
        key: "External Link",
        type: "links",
        items: [],
      };
      let links = [];
      if (this.json_data.link) {
        let l = this.json_data.link;
        let url = l.baseUrl + l.urlVariable;
        let link = {
          name: l.webSiteName,
          link: url,
        };
        links.push(link);
      }
      let link2 = {
        name: "The Subcellular Location of Proteins in Arabidopsis Database (SUBA)",
        link:
          "http://www.plantenergy.uwa.edu.au/applications/suba/flatfile.php?id=" +
          this.json_data.name,
      };
      links.push(link2);
      entry.items.push({
        title: "Links",
        links: links,
      });
      return entry;
    },
    getComments() {
      let entry = {
        key: "Community Comments",
        type: "comment_list",
        showComments: true,
        showRecent: true,
        items: [],
        helpTxt: this.helpTexts.comments,
      };
      let items = [];
      if (this.json_data.comments) {
        this.json_data.comments.forEach((c) => {
          let item = {};
          item.profile = {
            name: c.person.name,
            url: "/person?key=" + c.person.communityId,
          };
          item.comment = {
            text: c.notepadComment,
            url:
              process.env.VUE_APP_OLD_TAIR_URL +
              "/servlets/TairObject?type=notepad&id=" +
              c.notepadId,
          };
          item.date_posted = c.dateEntered;
          items.push(item);
        });
      }
      entry.items = items;
      if (entry.items.length == 0) {
        entry.items.push({ comment: { text: "No comments found" } });
      }
      return entry;
    },
    //utils
    getDateModified() {
      let date = new Date(this.json_data.dateLastModified);
      return this.dateToYMD(date);
    },
    dateToYMD(date) {
      var d = date.getDate();
      var m = date.getMonth() + 1; //Month from 0 to 11
      var y = date.getFullYear();
      return (
        "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d)
      );
    },
    openPostLink(url, data) {
      // Create a form element
      let form = document.createElement("form");
      form.action = url;
      form.method = "POST";
      form.target = "_blank"; // Open in new tab

      // Add the data as hidden input fields
      for (let key in data) {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = key;
        input.value = data[key];
        form.appendChild(input);
      }

      // Append the form to the body and submit it
      document.body.appendChild(form);
      form.submit();

      // Remove the form after submission
      document.body.removeChild(form);
    },
  },
};
</script>

<style scoped lang="scss">
.fixed-title {
  position: sticky;
  top: 0;
  background-color: #f1efec;
  z-index: 10;
  padding-left: 10px;
  padding-top: 10px;
}

.my-custom-scrollbar {
  position: relative;
  height: 200px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
