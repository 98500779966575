import Vue from "vue";
import store from "../store/store"; //
import Router from "vue-router";
import { browseRoutes } from "./browse_router";
import { detailRoutes } from "./detail_router";
import { searchRoutes } from "./search_router";
import { authRoutes } from "./auth_router";
import { downloadRoutes } from "./download_router";
import { newsRoutes } from "./news_router";
import TairHome from "@/views/TairHome";
import MyHome from "@/views/MyHome";
import PageNotFound from "../components/common/PageNotFound"
import SearchResults from "@/views/search/SearchResults";
import BulkSequenceSearch from "@/views/BulkSequenceSearch";

import OrganizationEditDetail from "@/views/edit/OrganizationEditDetail";
import PersonEditDetail from "@/views/edit/PersonEditDetail";
import RegistrationDetail from "@/views/edit/RegistrationDetail";
import Sequences from "@/views/search/Sequences";
import GeneClassSymbolRegistration from "@/views/submit/GeneClassSymbolRegistration";
import GeneClassSymbolRegistrationForm from "@/views/submit/GeneClassSymbolRegistrationForm";
import GeneClassSymbolDetailRegistrationForm from "@/views/submit/GeneClassSymbolDetailRegistrationForm";
import PermissionDenied from "../components/common/PermissionDenied";
import redirectMapping from "./redirectMapping";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "TairHome",
      component: TairHome,
    },
    {
      path: "/index.jsp",
      redirect: { name: "TairHome" },
    },
    authRoutes,
    browseRoutes,
    detailRoutes,
    downloadRoutes,
    newsRoutes,
    searchRoutes,

    {
      path: "/myhome",
      name: "MyHome",
      component: MyHome,
    },
    {
      path: "/sequences",
      name: "Sequences",
      component: Sequences,
    },
    {
      path: "/results",
      name: "SearchResults",
      component: SearchResults,
    },

    {
      path: "/portals/overview",
      name: "PortalsOverview",
      component: () => import("@/views/portals/PortalsOverview.vue"),
    },
    {
      path: "/submit/overview",
      name: "SubmitOverview",
      component: () => import("@/views/submit/SubmissionOverview.vue"),
    },
    {
      path: "/submit/gene_class_symbol_registration",
      name: "GeneClassSymbolRegistration",
      component: GeneClassSymbolRegistration,
    },
    {
      path: "/submit/gene_class_symbol_registration/form",
      name: "GeneClassSymbolRegistrationForm",
      component: GeneClassSymbolRegistrationForm,
    },
    {
      path: "/submit/gene_class_symbol_detail_registration/form",
      name: "GeneClassSymbolDetailRegistrationForm",
      component: GeneClassSymbolDetailRegistrationForm,
    },
    {
      path: "/tools/overview",
      name: "ToolsOverview",
      component: () => import("@/views/tools/ToolsOverview.vue"),
    },
    {
      path: "/about/overview",
      name: "AboutOverview",
      component: () => import("@/views/about/AboutOverview.vue"),
    },
    {
      path: "/register",
      name: "RegistrationDetail",
      component: RegistrationDetail,
    },
    {
      path: "/help",
      name: "HelpOverview",
      component: () => import("@/views/about/HelpOverview.vue"),
    },
    {
      path: "/contact",
      name: "Contact",
      component: () => import("@/views/about/Contact.vue"),
    },
    // {
    //   path: "/results",
    //   name: "SearchResults",
    //   component: SearchResults
    // },
    {
      path: "/type_sequences/:ids*",
      name: "BulkSequenceSearch",
      component: BulkSequenceSearch,
    },

    {
      path: "/browse/gene_family/:key",
      name: "GeneFamily",
      component: () => import("@/views/detail/GeneFamily.vue"),
    },
    {
      path: "/browse/microarray_experiments/:key",
      name: "MicroarrayExperiment",
      component: () => import("@/views/detail/MicroarrayExperimentDetail.vue"),
    },

    {
      path: "/edit/person",
      name: "PersonEditDetail",
      component: PersonEditDetail,
      beforeEnter: (to, from, next) => {
        const urlCommunityId = to.query.key;
        const storeCommunityId = store.getters["authProfile/getCommunityId"];
        const isTairCurator = store.getters["authProfile/getIsTairCurator"];
        if (urlCommunityId == storeCommunityId || isTairCurator) {
          next(); // Proceed as normal
        } else {
          next("/permission-denied"); // Redirect to a permission denied page if neither condition is met
        }
      },
    },
    {
      path: "/edit/organization",
      name: "OrganizationEditDetail",
      component: OrganizationEditDetail,
      beforeEnter: (to, from, next) => {
        const urlCommunityId = to.query.key;
        const isTairCurator = store.getters["authProfile/getIsTairCurator"];
        const affiliations = store.getters["authProfile/getAffiliations"];
        const urlCommunityIdNumber = Number(urlCommunityId);
        let hasAccess = false;
        if (affiliations) {
          hasAccess =
            affiliations.includes(urlCommunityIdNumber) || isTairCurator;
        } else {
          hasAccess = isTairCurator;
        }
        if (hasAccess) {
          next(); // Proceed as normal
        } else {
          next("/permission-denied"); // Redirect to a permission denied page if no conditions are met
        }
      },
    },
    {
      path: "/permission-denied",
      name: "PermissionDenied",
      component: PermissionDenied,
    },
    {
      path: "/download_files/*",
      beforeEnter: (to, from, next) => {
        const newPath = to.path.replace(
          "/download_files/",
          "/download/file?path="
        );
        next({ path: newPath });
      },
    },
    {
      path: "/pagenotfound",
      name: "PageNotFound",
      component: PageNotFound,
    },
    //redirects for external URLS
    {
        path: '/patmatch/release_notes.jsp',
        beforeEnter(to, from, next) {
            // Put the full page URL including the protocol http(s) below
            window.location.replace("https://conf.phoenixbioinformatics.org/display/COM/PatMatch+Notes")
        }
    },
    {
        path: '/help/faq.jsp',
        beforeEnter(to, from, next) {
            // Check if the hash part of the URL matches 'jobs' and redirect appropriately
            if (to.hash === '#jobs') {
                window.location.replace("https://conf.phoenixbioinformatics.org/display/COM/FAQ#FAQ-HowdoIpostajobopeningatTAIR?");
            }
        }
    },  
    {
      path: '/portals/nomenclature/index.jsp',
      beforeEnter(to, from, next) {
          // Put the full page URL including the protocol http(s) below
          window.location.replace("https://conf.phoenixbioinformatics.org/display/COM/Arabidopsis+Nomenclature")
      }
  },
    {
      path: '/patmatch/release_notes.jsp',
      beforeEnter(to, from, next) {
          // Put the full page URL including the protocol http(s) below
          window.location.replace("https://conf.phoenixbioinformatics.org/display/COM/PatMatch+Notes")
      }
  },
    {
      path: '/portals/masc/projects.jsp',
      beforeEnter() {
        window.location.href = "https://www.arabidopsisresearch.org/index.php/en/";
      }
    },
    {
      path: '/browse/Cereon/index.jsp',
      beforeEnter() {
        window.location.href = "https://conf.phoenixbioinformatics.org/pages/viewpage.action?pageId=154501128";
      }
    },
    {
      path: '/tools/go_term_enrichment.jsp',
      beforeEnter() {
        window.location.href = "https://v2.arabidopsis.org/tools/go_term_enrichment.jsp";
      }
    }, 
    {
      path: '/cgi-bin/gb2/gbrowse/arabidopsis/',
      beforeEnter() {
        window.location.href = "https://gbrowse.arabidopsis.org/cgi-bin/gb2/gbrowse/arabidopsis/";
      }
    },
    {
      path: '/cgi-bin/syntenyviewer2/showSynteny.pl',
      beforeEnter() {
        window.location.href = "https://v2.arabidopsis.org/cgi-bin/syntenyviewer2/showSynteny.pl";
      }
    },
    {
      path: '/servlets/mapper',
      beforeEnter() {
        window.location.href = "https://v2.arabidopsis.org/servlets/mapper";
      }
    },
    {
      path: '/phylogenes.arabidopsis.org/',
      beforeEnter() {
        window.location.href = "https://phylogenes.arabidopsis.org/";
      }
    },
    {
      path: '/biocyc/index.jsp',
      beforeEnter() {
        window.location.href = "https://pmn.plantcyc.org/organism-summary?object=ARA";
      }
    },
    {
      path: '/tools/bulk/motiffinder/index.jsp',
      beforeEnter() {
        window.location.href = "https://v2.arabidopsis.org/tools/bulk/motiffinder/index.jsp";
      }
    },
    {
      path: '/tools/bulk/locushistory/index.jsp',
      beforeEnter() {
        window.location.href = "https://conf.phoenixbioinformatics.org/display/COM/Bulk+downloading+Gene+Data";
      }
    },
    {
      path: '/Blast/index.jsp',
      beforeEnter() {
        window.location.href = "https://v2.arabidopsis.org/Blast/";
      }
    },
    {
      path: '/cgi-bin/patmatch/nph-patmatch.pl',
      beforeEnter() {
        window.location.href = "https://v2.arabidopsis.org/cgi-bin/patmatch/nph-patmatch.pl";
      }
    },
    {
      path: '/jsp/ChromosomeMap/tool.jsp',
      beforeEnter() {
        window.location.href = "https://v2.arabidopsis.org/jsp/ChromosomeMap/tool.jsp";
      }
    },
    {
      path: '/tools/bulk/*',
      beforeEnter(to, from, next) {
        if (to.path !== '/tools/bulk/protein/index.jsp') {
          window.location.href = "https://conf.phoenixbioinformatics.org/display/COM/Bulk+downloading+Gene+Data";
        }else{
          next("/search/protein");
        }
      }
    },       
    {
      path: '/submit/ExternalLinkSubmission.jsp',
      beforeEnter() {
        window.location.href = 'https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-ExternalLinks';
      }
    },
    {
      path: '/submit/genefamily_submission.jsp',
      beforeEnter() {
        // Redirect to a not found or specific error page
        window.location.href = '/pagenotfound'; // Change this URL to wherever you handle error or not found cases
      }
    },
    {
      path: '/doc/submit/functional_annotation/123',
      beforeEnter() {
        window.location.href = 'https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-GeneFunctionData';
      }
    },
    {
      path: '/submit/marker_submission.jsp',
      beforeEnter() {
        window.location.href = 'https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-MarkerandPolymorphismData';
      }
    },
    {
      path: '/submit/phenotype_submission.jsp',
      beforeEnter() {
        window.location.href = 'https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-Phenotypes';
      }
    },
    {
      path: '/submit/protocol_submission.jsp',
      beforeEnter() {
        window.location.href = 'https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-Protocols';
      }
    },
    {
      path: '/submit/gene_annotation.submission.jsp',
      beforeEnter() {
        window.location.href = 'https://conf.phoenixbioinformatics.org/display/COM/Data+Submission#DataSubmission-GeneStructureAdditions/Modifications';
      }
    },
    {
      path: "*",
      beforeEnter: (to, from, next) => {
        const pathSegments = to.path.split("/").filter((segment) => segment);
        const lastSegment = pathSegments[pathSegments.length - 1];
        const baseRoute =
          pathSegments.length > 1
            ? pathSegments.slice(0, -1).join("/")
            : to.path;

        const mappings = redirectMapping[baseRoute] || redirectMapping[to.path];
        if (mappings) {
          let mapping;
          // Check if we need to handle path segments
          if (!isNaN(parseInt(lastSegment))) {
            // It's a numeric ID, find the mapping that handles IDs
            mapping = mappings.find(
              (m) =>
                m.type === "person_detail" || m.type === "organization_detail"
            );
            if (mapping && mapping.targetPath) {
              next({ path: mapping.targetPath(lastSegment) });
              return;
            }
          } else {
            // Handle based on query parameters
            mapping = mappings.find(
              (m) => to.query.action === m.action && to.query.type === m.type
            );
            if (mapping && mapping.targetPath) {
              next({ path: mapping.targetPath(to.query) });
              return;
            }
            // do accession stuff
            if (to.query.accession) {
              mapping = mappings.find((m) => m.isDefault); // Adjusted to find 'isDefault'
              if (mapping && mapping.targetPath) {
                const redirectPath = mapping.targetPath(to.query);
                next({ path: redirectPath });
                return;
              }
            }
          }
          next(); // No specific mapping found, continue with default behavior
        } else {
          next( {name: "PageNotFound"}); // No mappings for this path, continue with default behavior
        }
      },
    },
  ],
});
