<template>
  <div class="list-group-item flex-column align-items-start">
    <div v-if="content.type == 'default'" class="row-wrapper">
      <div class="title" v-bind:class="{ selected: entryIsSelected }">
        {{ content.title }}
      </div>
      <div class="content">
        {{ content.name }}
      </div>
    </div>
    <div v-if="content.type == 'bands'" class="row-wrapper bands">
      <div class="title" v-bind:class="{ selected: entryIsSelected }">
        <span>{{ content.title }}</span>
        <div class="ml-2" v-if="content.helpTxt">
          <b-tooltip :target="'help-header'" triggers="hover">
            <p
              v-html="content.helpTxt"
              class="overflow-div d-flex flex-column text-left"
            ></p>
          </b-tooltip>
          <b-icon
            class="ml-2"
            :id="'help-header'"
            icon="question-circle-fill"
          ></b-icon>
        </div>
      </div>
      <div class="content">
        <div v-for="(b, i) in content.bands" :key="i">
          <div class="row" v-if="b.type == 'table' || b.type == 'multiline'">
            <div
              :class="sectionHeaderClass(i)"
              class="col-sm-10 d-flex justify-content-left"
            >
              <section-title :data="b" :index="i" />
            </div>
            <div class="row w-100">
              <div v-if="!b.type || b.type == ''" class="m-2 text-left">
                {{ b.text }}
              </div>
              <table-section
                v-if="b.type == 'table'"
                :data="b"
                class="col-sm-11"
              />
              <multi-line-section
                v-if="b.type == 'multiline'"
                :data="b"
                class="col-sm-12"
              />
            </div>
          </div>
          <div class="row" v-else :class="sectionHeaderClass(i)">
            <div class="col-sm d-flex justify-content-start">
              <section-title :data="b" :index="i" />
            </div>
            <div v-if="!b.type || b.type == ''" class="col-sm-10 text-left">
              {{ b.text }}
            </div>
            <!-- Single Link -->
            <div
              v-if="b.type == 'link'"
              class="col-sm-10 d-flex justify-content-start"
            >
              <div class="d-flex flex-column align-items-start">
                <div>
                  <link-wrapper v-if="b.link" :link="b.link" :name="b.text" />
                  <span v-else class="text-left">{{ b.text }}</span>
                </div>
                <div class="mb-2" v-if="b.buttons">
                  <div v-for="(btn, i) in b.buttons" :key="i">
                    <a :href="btn.url" class="btn btn-info ml-2">{{
                      btn.name
                    }}</a>
                    <a v-if="btn.helpTxt" :href="btn.link" target="_blank">
                      {{ btn.helpTxt }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Multiple Links (Inside Rows) -->
            <div
              v-if="b.type == 'links'"
              class="col-sm-10 d-flex justify-content-start"
            >
              <div
                v-for="(name_link, i) in b.links"
                class="d-flex flex-column align-items-start"
                :key="i"
              >
                <div class="m-2">
                  <link-wrapper
                    v-if="name_link.link"
                    :link="name_link.link"
                    :name="name_link.name"
                  />
                </div>
              </div>
            </div>
            <!-- Multiple Links (Comma Seperated) -->
            <div v-if="b.type == 'links_commasep'" class="col-sm-10">
              <div
                class="m-2"
                style="display: flex; flex-wrap: wrap; align-items: center"
              >
                <span
                  v-for="(name_link, i) in b.items"
                  :key="i"
                  style="white-space: nowrap; margin-right: 5px"
                >
                  <a
                    v-if="name_link.link"
                    :href="name_link.link"
                    rel="noopener noreferrer"
                    style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    >{{ name_link.name }}</a
                  >
                  <span v-if="i < b.items.length - 1">, </span>
                </span>
              </div>
            </div>
            <!-- IFrame -->
            <div
              v-if="b.type == 'iframe'"
              class="col-sm-10 d-flex flex-column justify-content-start"
              style="height: 300px"
            >
              <div class="d-flex justify-content-end align-items-start">
                <a @click.prevent="reloadIFrame" class="m-2"
                  >Center on {{ b.name }}</a
                >
                <span>|</span>
                <a
                  :href="b.fullScreenUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="m-2"
                  >Full-screen view</a
                >
              </div>

              <iframe
                ref="jbrowseIframe"
                name="jbrowse"
                loading="lazy"
                :src="b.jbrowseUrl"
                style="border: 1px solid black; width: 650px; height: 280px"
              ></iframe>
            </div>
            <!-- html -->
            <div
              v-if="b.type == 'html'"
              class="col-sm-10 d-flex justify-content-start"
            >
              <div class="m-2 text-left" v-html="b.html"></div>
            </div>
            <!-- Link List -->
            <div
              v-if="b.type == 'link_list'"
              class="col-sm-10 d-flex flex-column align-items-start mt-2"
            >
              <div v-for="(l, i) in b.items" class="mr-2" :key="i">
                <link-wrapper v-if="l.link" :link="l.link" :name="l.text" />
              </div>
            </div>
            <!-- Small Table -->
            <table-section
              v-if="b.type == 'small-table'"
              :data="b"
              class="col-sm-10"
            />
          </div>

          <!-- Multiple Links (Full) -->
          <div
            v-if="b.type == 'links'"
            class="col-sm-10 d-flex flex-column justify-content-start"
          >
            <div style="max-height: 400px; overflow: auto">
              <b-card-group
                v-for="(list_obj, i) in b.items"
                :key="i"
                style="width: 100%"
              >
                <b-card
                  :header="list_obj.title"
                  class="text-left font-weight-bold"
                >
                  <b-list-group>
                    <b-list-group-item
                      v-for="(link_obj, j) in list_obj.links"
                      :key="j"
                      class="text-left font-weight-normal"
                    >
                      <a :href="link_obj.link" target="_blank">{{
                        link_obj.name
                      }}</a></b-list-group-item
                    >
                  </b-list-group>
                </b-card>
              </b-card-group>
            </div>
          </div>
          <!-- String List -->
          <div
            v-if="b.type == 'string_list'"
            class="col-sm-10 d-flex flex-column justify-items-start"
          >
            <div
              v-for="(l, i) in b.items"
              class="mr-2 d-flex justify-content-start"
              :key="i"
            >
              {{ l }}
            </div>
          </div>
          <!-- Image List -->
          <div
            v-if="b.type == 'img_list'"
            class="col-sm-10 d-flex justify-content-start col-wrapper"
          >
            <div class="m-2" v-for="(list_obj, i) in b.items" :key="i">
              <a
                v-if="list_obj.img_url"
                :href="list_obj.full_url"
                target="_blank"
                class="pg-link"
                ><img class="mr-2" :src="list_obj.img_url" height="100px"
              /></a>
            </div>
          </div>
          <!-- Homologs -->
          <div
            v-if="b.type == 'homologs'"
            class="col-sm-10 d-flex justify-content-start mt-2"
          >
            <HomologsSection
              :content="b.content"
              :treeId="content.meta.treeId"
            />
          </div>
          <!-- Panther Tree Viewer -->
          <div
            v-if="b.type == 'panther_tree_viewer'"
            class="col-sm-10 d-flex justify-content-start"
          >
            <div class="mr-2">
              <img
                src="@/assets/PantherTree.png"
                height="100px"
                alt="Panther Tree"
              />
            </div>
            <div class="mr-2 pg-link">
              <a :href="b.pgLink" target="_blank"
                ><img
                  src="@/assets/phylogeneslogo.png"
                  height="100px"
                  alt="Phylogenes logo"
              /></a>
            </div>
            <div class="pg-title"><a>View complete family and tree</a></div>
          </div>
          <!-- Image -->
          <image-selector v-if="b.type == 'image'" :band="b" />
          <!-- Comment List -->
          <div
            v-if="b.type == 'comment_list'"
            class="col-sm-10 d-flex flex-column justify-content-start"
          >
            <div v-if="b.showComments">
              <div v-for="(c, i) in displayedComments(b)" :key="i" class="m-2">
                <div v-if="c.profile" class="d-flex justify-content-start">
                  <a :href="c.profile.url" target="_blank">{{
                    c.profile.name
                  }}</a
                  >, {{ c.date_posted }}
                </div>
                <div class="d-flex justify-content-start">
                  <a
                    v-if="c.comment.url"
                    class="text-left text-overflow"
                    :href="c.comment.url"
                    target="_blank"
                    >{{ c.comment.text }}</a
                  >
                  <span class="text-left text-overflow" v-else>{{
                    c.comment.text
                  }}</span>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mb-2">
              <b-button class="ml-2" size="md" disabled
                >Add My Comment</b-button
              >
              <b-button
                class="ml-2"
                size="md"
                @click="
                  {
                    b.showComments = !b.showComments;
                  }
                "
                ><span v-if="b.showComments">Hide Comments</span
                ><span v-else>Show Comments</span></b-button
              >
              <!-- <b-button
				  class="ml-2"
				  size="md"
				  @click="
					{
					  b.showRecent = !b.showRecent;
					}
				  "
				  ><span v-if="b.showRecent">Show All Comments</span
				  ><span v-else>Show Recent Comments</span></b-button
				> -->
            </div>
          </div>
          <!-- Sequences -->
          <div
            v-if="b.type == 'sequence'"
            class="col-sm-10 d-flex flex-column justify-content-start"
          >
            <div>
              <div class="d-flex flex-column justify-content-start">
                <span
                  v-for="(line, index) in b.chunks"
                  :key="index"
                  style="
                    text-align: left;
                    font-family: 'Courier New', monospace;
                    font-weight: bold;
                  "
                >
                  {{ line }}
                </span>
              </div>
              <b-button class="ml-2 mt-2" size="md" @click="copyToClipboard(b.chunks)"
                >Copy Sequence</b-button
              >
              <b-button class="ml-2 mt-2" size="md" @click="b.openLink"
                >Send to BLAST</b-button
              >
              <div v-if="showSuccessAlert" class="alert alert-success mt-2" role="alert">
                Sequence copied to clipboard!
              </div>
              <div v-if="showErrorAlert" class="alert alert-danger mt-2" role="alert">
                Failed to copy the sequence.
              </div>
            </div>
          </div>

          <hr v-if="i < content.bands.length - 1" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import MultiLineSection from "../common/MultiLineSection.vue";
import TableSection from "../common/TableSection.vue";
import LinkWrapper from "../common/LinkWrapper.vue";
import HomologsSection from "./HomologsSection.vue";
import SectionTitle from "./SectionTitle.vue";
import ImageSelector from "./ImageSelector.vue";

export default {
  name: "BaseEntry",
  props: ["content", "selectedColIdx", "currIdx"],
  components: {
    MultiLineSection,
    TableSection,
    LinkWrapper,
    HomologsSection,
    SectionTitle,
    ImageSelector,
  },
  data() {
    return {
      selectedImgType: "",
      loading: false,
      errorImg: false,
      entryIsSelected: false,
      sortKey: "",
      showSuccessAlert: false,
      showErrorAlert: false,
    };
  },
  mounted() {
    this.entryIsSelected = false;
  },
  computed: {},
  watch: {
    selectedColIdx: function (newVal, oldVal) {
      if (newVal == this.currIdx) {
        this.entryIsSelected = true;
      } else {
        this.entryIsSelected = false;
      }
    },
  },
  methods: {
    reloadIFrame() {
      let iframeSrc = this.$refs.jbrowseIframe[0].src;
      this.$refs.jbrowseIframe[0].src = "";
      this.$nextTick(() => {
        this.$refs.jbrowseIframe[0].src = iframeSrc;
      });
    },
    getStringFields(fields) {
      if (!fields) return [];
      return fields.filter((field) => field.cellType === "string");
    },
    getHtmlRows(fields) {
      if (!fields) return [];
      return fields.filter((field) => field.cellType === "html");
    },
    displayedComments(b) {
      if (b.showRecent) {
        return b.items.slice(-2); // Last two items
      } else {
        return b.items; // All items
      }
    },
    sectionHeaderClass(index) {
      return {
        "mr-2": true,
        "bg-white": index % 2 === 0,
        "bg-light": index % 2 !== 0,
      };
    },
    copyToClipboard(chunks) {
      const sequenceText = chunks.map(line => {
        return line.substring(line.indexOf(' ') + 1).replace(/\s+/g, '');
      }).join('');
      navigator.clipboard.writeText(sequenceText)
        .then(() => {
          this.showAlert('success');
        })
        .catch(err => {
          this.showAlert('error');
          console.error('Failed to copy the sequence: ', err);
        });
    },
    showAlert(type) {
      if (type === 'success') {
        this.showSuccessAlert = true;
        setTimeout(() => this.showSuccessAlert = false, 3000); // Hide after 3 seconds
      } else if (type === 'error') {
        this.showErrorAlert = true;
        setTimeout(() => this.showErrorAlert = false, 3000); // Hide after 3 seconds
      }
    }
  },
};
</script>
<style scoped lang="scss">
.text-left {
  text-align: left;
}
.column {
  flex: 0 0 200px;
}
.title {
  display: flex;
  width: 98%;
  align-items: flex-start;
  /* based on 'entryIsSelected', change background color*/
  background-color: #f5f7f5;
  margin: 10px;
  padding-left: 10px;
  font-weight: bold;
  font-size: large;
}
.selected {
  background-color: #a6b542;
}
.col-wrapper {
  overflow-x: scroll;
}
.overflow-div {
  max-height: 200px;
  overflow: scroll;
}
.ellipsis {
  overflow: hidden;
  display: inline-block;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: underline;
}
.text-overflow {
  width: 100%; /* Adjust this as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.authors {
  font-size: small;
  text-align: left;
}
.truncate {
  text-overflow: ellipsis;
}
.pg-link {
  cursor: pointer;
  height: 110px;
}
.pg-link:hover {
  background: #feffbb;
}
.pg-title {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-self: center;
}
.img-wrapper {
  height: 400px;
  width: 100%;
  background-color: blue;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid black;
}
.img-wrapper:hover {
  border: 2px solid black;
  cursor: grab;
}

.img-wrapper > img {
  position: relative;
}
.row-wrapper {
  border: black 1px solid;
}
.content {
  margin: 10px;
  min-height: 100px;
  padding-left: 20px;
}
.sorted-asc::after {
  content: "▲";
}

.sorted-desc::after {
  content: "▼";
}
</style>
