<template>
  <div class="searchWrapper d-flex flex-column">
    <div class="pageTop d-flex justify-content-between">
      <div>
        <div class="d-none d-md-block">
          <div class="font-weight-bold text-left title ml-2">
            Browse Recent Added Literature
          </div>
          <div class="text-left ml-2">{{ this.date2 }} - {{ this.date1 }}</div>
        </div>
        <div>
          <div class="pageDesc mt-2">
            <span
              class="text-left px-4 statement"
              v-html="queryStatement"
            ></span>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center mr-2">
        <label for="sortSelect" class="mr-2">Sort by:</label>
        <b-form-select
          id="sortSelect"
          v-model="selectedSort"
          :options="sortOptions"
          :style="{ width: '250px' }"
          @change="sortLiterature"
        ></b-form-select>
      </div>
    </div>

    <div class="pageContent">
      <div class="d-flex align-content-left m-2">
        Displaying {{ localCurrentPage * perPage - perPage + 1 }} -
        {{
          items.length < localCurrentPage * perPage
            ? items.length
            : localCurrentPage * perPage
        }}
        of {{ items.length }} results
      </div>
      <div class="tableWrapper">
        <ResultsTable
          :items="items"
          :fields="fields"
          :loading="loading"
          :error="error"
          :showSelect="false"
          @update:currentPage="localCurrentPage = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PostsService from "@/services/PostsService";
import { BPagination } from "bootstrap-vue";
import TopMenu from "@/components/search/TopMenu.vue";
import ResultsTable from "@/components/search/ResultsTable.vue";

export default {
  name: "RecentLiteratureBrowse",
  data() {
    return {
      category: "gene",
      searchTerm: "",
      lociMatchCount: 0,
      geneModelCount: 0,
      selected: [],
      items: [],
      fields: [],
      loading: false,
      error: false,
      queryStatement: "",
      localCurrentPage: 1,
      perPage: 25,
      pages: 0,
      date1: "",
      date2: "",
      sortOptions: [
        { value: "journal", text: "Journal" },
        { value: "author", text: "Author" },
      ],
      selectedSort: "journal",
    };
  },
  mounted() {
    document.title = "Recently added literature";
    this.init();
  },
  components: {
    BPagination,
    ResultsTable,
  },
  methods: {
    init() {
      let search_params = {
        searchTerms: [
          {
            year_from: "2024",
          },
          {
            year_to: "2024",
          },
        ],
        rowsLimit: 1000,
      };
      this.loadPubResults(search_params);
    },
    sortLiterature() {
      // Your sorting logic here

      if (this.selectedSort === "journal") {
        this.items.sort((a, b) => {
          let nameA = a.source.name.toUpperCase();
          let nameB = b.source.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      } else if (this.selectedSort === "author") {
        this.items.sort((a, b) => {
          let nameA = a.authors.name.toUpperCase();
          let nameB = b.authors.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      }
    },
    async loadPubResults(search_params) {
      this.fields = [];
      this.fields.push({ key: "row_num", label: " # " });
      this.fields.push({
        key: "title",
        label: "Title",
        cellType: "name_link",
        class: "wide",
      });
      // this.fields.push({
      //   key: "source",
      //   label: "Source",
      //   cellType: "name_link",
      //   class: "narrow",
      // });
      this.fields.push({
        key: "year",
        label: "Year",
        cellType: "name_link",
      });
      this.fields.push({
        key: "doi",
        label: "DOI",
        cellType: "name_link",
      });
      this.fields.push({
        key: "pmid",
        label: "PMID",
        cellType: "name_link",
      });
      this.loading = true;
      let items = [];
      try {
        let response = await PostsService.fetchPublicationSearch(search_params);
        if (response.data.error) {
          this.error = true;
          return;
        }
        let docs = response.data.docs;

        //sort by docs.date_last_modified "Sat Jan 20 22:00:20 PST 2024"
        docs.sort((a, b) => {
          let dateA = new Date(a.date_last_modified);
          let dateB = new Date(b.date_last_modified);
          return dateB - dateA;
        });

        //show only last two dates starting from the most recent
        let mostRecentDate = new Date(docs[0].date_last_modified[0]);
        let twoWeeksAgo = new Date(mostRecentDate);
        twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
        this.date1 = mostRecentDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });

        this.date2 = twoWeeksAgo.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });

        let lastTwoWeeksDocs = docs.filter((doc) => {
          let docDate = new Date(doc.date_last_modified);
          return docDate >= twoWeeksAgo && docDate <= mostRecentDate;
        });
        let pubDates = lastTwoWeeksDocs.map((d) => ({
          date: d.date_last_modified[0],
          reference: d.referenceId,
        }));

        let totalMatch = response.data.total;

        for (const d of lastTwoWeeksDocs) {
          let footerHtml = `<p class="text-left">`;
          footerHtml += `Authors: ${d.authors[0]} <br>`;
          footerHtml += `Source: ${d.pubName[0]} <br>`;
          if (d.keywords) {
            footerHtml += `Keywords: ${d.keywords}</br>`;
          } else {
            footerHtml += `Keywords: No keywords available</br>`;
          }
          footerHtml += `Loci: `;
          if (d.loci) {
            let loci = d.loci.map((l) => {
              let split = l.split("_");
              return `<a href="/locus?key=${split[1]}" target="_blank">${split[0]}</a>`;
            });
            if (loci.length > 0) {
              footerHtml += loci.join(", ");
            } else {
              footerHtml += "No loci associated";
            }
          } else {
            footerHtml += "No loci associated";
          }
          footerHtml += `</p>`;
          items.push({
            id: d.id,
            selected: false,
            authors: {
              name: d.authors[0],
            },
            title: {
              name: d.title[0],
              link: `/publication?key=${d.referenceId}`,
            },
            source: {
              name: d.pubName[0],
            },
            year: {
              name: d.year,
            },
            doi: {
              name: d.doi ? d.doi : "No DOI available",
              link: d.doi ? `https://doi.org/${d.doi}` : null,
            },
            pmid: {
              name: d.pubmedId,
              link: `https://pubmed.ncbi.nlm.nih.gov/${d.pubmedId}/`,
            },
            keywords: {
              name: d.keywords ? d.keywords.join(";") : "",
            },
            _showDetails: true,
            html: footerHtml,
          });
        }

        this.items = items;
        this.sortLiterature();
        search_params.matchCount = totalMatch;
        this.loading = false;
      } catch (error) {
        console.log(
          error.response ? error.response.statusText : "Unknwon error"
        );
        this.loading = false;
        this.error = true;
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
}
.statement {
  font-size: 20px;
}
.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.custom-gap {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 10px;
}
.keySearchColor {
  color: #ff0000;
}

.ROWheader {
  align-items: center;
  justify-content: center;
  vertical-align: text-top;
}
.tight-checkbox {
  padding: 0px;
  margin: 0px;
}
.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hashtag-icon {
  font-size: 16px;
  padding-bottom: 7px;
  justify-content: center;
  padding-left: 2px;
}
.center-content {
  display: flex;
  align-items: center; // Vertically center aligned
  justify-content: center; // Horizontally center aligned
}
</style>
