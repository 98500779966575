<template>
  <div class="container">
    <div v-if="!is_not_login" id="content" class="clearfix">
      <div style="width: 100%; margin-left: 5px; text-align: center">
        <p class="font-weight-bold">
          Welcome, {{ json_data.FIRST_NAME }} {{ json_data.LAST_NAME }}
        </p>

        {{ subscriber_info }}<br />

        <p class="mt-2 font-weight-bold">{{ subscriber_message }}</p>

        <div v-if="!isSubscribed">
          <p>Subscribe using one of the buttons below for unlimited access.</p>
          <button class="btn btn-primary mr-2"><a :href="subscribeLink" target="_blank"  class="text-white">Subscribe</a></button>
          <button class="btn btn-primary"><a :href="activateSubLink" target="_blank" class="text-white">Activate Subscription</a></button>
        </div>
      </div>

      <div class="d-flex justify-content-left">
        <div id="leftcolumn">
          <p class="font-weight-bold">Address</p>
          <div v-html="getAddress()"></div>
          <br />
          <p>
            <a :href="'/person?key=' + communityId"
              >View my public profile page</a
            >
          </p>
          <p>
            <a :href="'/edit/person?key=' + communityId">Update Profile</a>
          </p>

          <table border="0" cellspacing="0" cellpadding="1">
            <tbody>
              <tr>
                <td class="sm" colspan="2">
                  <font class="font-weight-bold">Affiliated Organizations</font>
                </td>
              </tr>
              <tr v-for="(o, index) in json_data.AFFILIATIONS" :key="index">
                <td class="sm" colspan="2">
                  <a :href="`/organization?key=${o.community_id}`">{{
                    o.name
                  }}</a>
                </td>
              </tr>
            </tbody>
          </table>

          <p class="font-weight-bold mt-4">Contribute Data to TAIR</p>

          <p>
            <a href="/submit/gene_class_symbol_registration"
              >Submit New Gene Class Symbol</a
            ><br />

            <a href="https://goat.phoenixbioinformatics.org"
              >Online Submission for Authors and Others</a
            >
          </p>
        </div>
        <div id="rightcolumn">
          <div v-if="json_data.IS_TAIR_CURATOR == 'T'">
            <table border="0" cellpadding="1" cellspacing="0">
              <tbody>
                <tr>
                  <td class="sm">
                    <font class="font-weight-bold">ABRC Stock Ordering</font>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td class="sm">
                    Need to order from ABRC, or view your old orders and
                    invoices? Go to
                    <a href="https://abrc.osu.edu" target="_blank"
                      >ABRC's new website</a
                    >.
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <td class="sm">
                    <font class="font-weight-bold">TAIR Curator Tools</font>
                  </td>
                </tr>
              </tbody>
            </table>

            <table>
              <tbody>
                <tr>
                  <td class="sm">
                    <a
                      href="https://arabidopsis.org/servlets/processor?type=genesymbol&amp;update_action=view_symbol&amp;from=curator&amp;status=new"
                      target="_blank"
                      >Review Gene Class Symbols</a
                    >
                  </td>
                </tr>
                <tr>
                  <td class="sm">
                    <a href="/browse/gene_symbols">Browse Gene Class Symbols</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>You are not logged in. Please login to view this page.</p>
    </div>
  </div>
</template>
<script>
import Api from "@/services/Api";
import { mapGetters } from "vuex";
import { getSubscriptionStatus } from "@/services/SubscriptionService";
export default {
  name: "MyHome",
  data() {
    return {
      json_data: {},
      is_not_login: false,
      communityId: null,
      subscriber_message: "",
      isSubscribed: false,
      isSymbolRegEnabled: false,
    };
  },
  mounted() {
    // Fetch data from API
    this.init();
  },
  computed: {
    subscriber_info() {
      if(this.isSubscribed && this.isSymbolRegEnabled) {
        return "TAIR Subscriber Account plus Gene Class Symbol registration - includes full access to TAIR pages and Gene Class Symbol registration";
      } else if(this.isSubscribed && !this.isSymbolRegEnabled) {
        return "TAIR Subscriber Account - includes full access to TAIR pages without Gene Class Symbol registration";
      } else if(!this.isSubscribed && this.isSymbolRegEnabled) {
        return "Basic TAIR Account plus Gene Class Symbol registration - includes metered access to TAIR pages and Gene Class Symbol registration";
      } else {
        return "Basic TAIR Account - includes metered access to TAIR pages without Gene Class Symbol registration";
      }
    },
    subscribeLink() {
      return `${process.env.VUE_APP_LOGIN_BASE_URL}/#/contentaccess/subscription?partnerId=tair&redirect=${process.env.VUE_APP_UI_BASE_URL}`;
    },
    activateSubLink() {
      return `${process.env.VUE_APP_LOGIN_BASE_URL}/#/contentaccess/activation?partnerId=tair&subscriptionType=activate&redirect=${process.env.VUE_APP_UI_BASE_URL}/myhome`;
    }
  },
  methods: {
    ...mapGetters({
      getCommunityId: "authProfile/getCommunityId",
    }),
    goToSubscribe(link) {
      window.location.href = link;
    },
    getAddress() {
      let address = "";
      //   if (this.json_data.INSTITUTIONNAME) {
      //     address += this.json_data.INSTITUTIONNAME + "<br />";
      //   }
      if (this.json_data.ADDRESS) {
        address += this.json_data.ADDRESS + "<br />";
      }
      if (this.json_data.CITY) {
        address += this.json_data.CITY + ", ";
      }
      if (this.json_data.REGION) {
        address += this.json_data.REGION + " - ";
      }
      if (this.json_data.POSTAL_CODE) {
        address += this.json_data.POSTAL_CODE + "<br />";
      }
      if (this.json_data.COUNTRY) {
        address += this.json_data.COUNTRY;
      }
      return address;
    },
    async init() {
      let communityId = this.getCommunityId();
      if (!communityId) {
        this.is_not_login = true;
        return;
      }
      this.communityId = communityId;
      const response = await Api().get(
        `/user/person-details?communityId=${this.communityId}`
      );

      this.json_data = response.data;
      this.isSymbolRegEnabled = this.json_data.REGISTERING_FOR_ABRC === "T";
      let responseSub = await getSubscriptionStatus();
      if (responseSub.subscribed) {
        this.isSubscribed = true;
        let expDate = new Date(responseSub.expDate);
        //only date part
        expDate = expDate.toISOString().split("T")[0];
        this.subscriber_message = `You're subscribed until ${expDate}.`;
      } else {
        this.subscriber_message = "";
        this.isSubscribed = false;
      }
    },
  },
};
</script>
<style scoped>
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  text-align: left;
}
</style>
